<template>
  <secured-area
    :operations="['List_Season_Compulsories', 'Set_Season_Compulsories']"
  >
    <h1>Figures de précision "saisonnière"</h1>
    <collapse-panel title="Filtres de recherche">
      <b-container fluid>
        <b-form-row>
          <b-col md="3">
            <form-field caption="Saison :">
              <season-selector
                v-model="selectedSeason"
                :options="[2023, 2022, 2021]"
                @change="refresh()"
              />
            </form-field>
          </b-col>
          <b-col xs="auto">
            <form-field caption="Type de compétition :">
              <b-form-select
                v-model="selectedCompetitionType"
                :options="competitionTypesForSelect"
                @change="selectedLevel=null; selectedCategory=null; refresh()"
              />
            </form-field>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <form-field caption="Niveau :">
              <b-form-select
                v-model="selectedLevel"
                :options="levelsForSelect"
                @change="selectedCategory=null; refresh()"
              />
            </form-field>
          </b-col>
          <b-col>
            <form-field caption="Catégorie :">
              <b-form-select
                  v-model="selectedCategory"
                  :options="categoriesForSelect"
                  @change="refresh()"
                />
            </form-field>
          </b-col>
        </b-form-row>
      </b-container>
    </collapse-panel>
    <div v-show="nbCompulsoriesToSelect > 0" class="ml-5">
      <b>Sélectionnez les {{ nbCompulsoriesToSelect }} figure(s) possibles pour l'année pour cette catégorie à ce niveau</b> :
    </div>

    <div>
      <b-form-checkbox-group
        v-model="selected"
        :options="compulsories"
        @change="selectionChange"
        stacked
        class="ml-5"
      />
    </div>
      <b-button
        :disabled="!changed"
        @click="saveChanges"
        class="float-right mr-5"
      >
        Enregistrer les modifications
      </b-button>

  </secured-area>
</template>

<script>
import seasonSelector from '@/components/SeasonSelector.vue';
import { REF_COMPETITION_TYPE, REF_LEVEL, REF_CATEGORY, REF_COMPULSORY, SEASON_COMPETITION_TYPE_CATEGORY_LEVEL, SEASON_COMPULSORY } from "@/LocalDB";
import { store } from "@/services/store";
import axios from "axios";
import { mapActions } from 'vuex';
import SecuredArea from '@/components/Security/NewSecuredArea.vue';
import FormField from "@/components/Panels/FormField";
import CollapsePanel from "@/components/Panels/CollapsePanel";

export default {
  components:{ seasonSelector, SecuredArea, FormField, CollapsePanel, },
  data(){
    return {
      selectedSeason : null,
      selectedCompetitionType: null,
      selectedLevel: null,
      selectedCategory: null,
      selected: [],
      selectionRead: '',
      nbCompulsoriesToSelect: -1,
      changed: false,
    }
  },
  computed:{
    competitionTypesForSelect(){
      console.log('competitionTypes');
      var ret = REF_COMPULSORY.query()
              .where(c => !c.isArchived)
              .get()
              .map(c => c.competition_type)
              .filter((value, index, self) => { return self.indexOf(value) === index; })
              .map(ct => { return REF_COMPETITION_TYPE.query().where("code", ct).first(); })
              .map(ct => { return { value: ct.code, text: ct.name }});
      console.log(ret);
      return ret;
    },
    levelsForSelect()
    {
      if(this.selectedCompetitionType == null)
        return [];

      var ret = REF_COMPULSORY.query()
              .where(c => !c.isArchived)
              .where(c => c.competition_type == this.selectedCompetitionType)
              .get()
              .map(c => c.level)
              .filter((value, index, self) => { return self.indexOf(value) === index; })
              .map(l => { return REF_LEVEL.query().where('code', l).first(); })
              .map(l => { return { value: l.code, text: l.name }});
      console.log(ret);
      return ret;
    },
    categoriesForSelect()
    {
      return REF_COMPULSORY.query()
              .where(c => !c.isArchived)
              .where(c => c.competition_type == this.selectedCompetitionType)
              .where(c => c.level == this.selectedLevel)
              .get()
              .map(c => c.category)
              .filter((value, index, self) => { return self.indexOf(value) === index; })
              .map(c => { return REF_CATEGORY.query().where('code', c).first(); })
              .map(c => { return { value: c.code, text: c.name }});
    },
    compulsories(){
      return REF_COMPULSORY.query()
              .where(c => !c.isArchived)
              .where(c => c.competition_type == this.selectedCompetitionType)
              .where(c => c.level == this.selectedLevel)
              .where(c => c.category == this.selectedCategory)
              .get()
              .sort((a,b) => (a.code < b.code) ? -1 : ((a.code > b.code) ? 1 : 0))
              .map(c => { return { value: c.id, text: c.code + ' - ' + c.name }; })
              //.filter((value, index, self) => { return self.indexOf(value) === index; })
    },
  },
  methods: {
    ...mapActions('synchro', ['synchronizeSeasonCompulsories'] ),

    refresh(){
      this.selected = SEASON_COMPULSORY.query()
              .where(sc => sc.year == this.selectedSeason)
              .where(sc => sc.COMPULSORY.competition_type == this.selectedCompetitionType)
              .where(sc => sc.COMPULSORY.level == this.selectedLevel)
              .where(sc => sc.COMPULSORY.category == this.selectedCategory)
              .get()
              .map(c => c.compulsory_id);
      console.log('refresh', { year: this.selectedSeason, competition_type: this.selectedCompetitionType, level: this.selectedLevel, category: this.selectedCategory, selected: this.selected});
      this.selectionRead = SEASON_COMPULSORY.query()
              .where(sc => sc.year == this.selectedSeason)
              .where(sc => sc.COMPULSORY.competition_type == this.selectedCompetitionType)
              .where(sc => sc.COMPULSORY.level == this.selectedLevel)
              .where(sc => sc.COMPULSORY.category == this.selectedCategory)
              .get()
              .map(c => c.compulsory_id)
              .sort((a,b) => (a > b) ? -1 : ((a < b) ? 1 : 0))
              .join(',');
      var rule = SEASON_COMPETITION_TYPE_CATEGORY_LEVEL.query()
              .where(i => i.year == this.selectedSeason)
              .where(i => i.competition_type == this.selectedCompetitionType)
              .where(i => i.level == this.selectedLevel)
              .where(i => i.category == this.selectedCategory)
              .first();
      rule = this.selectedLevel == "novice" ? { compulsories: 4 } : { compulsories : 6}
      this.nbCompulsoriesToSelect = (rule == null) ? -1 : rule.compulsories;
    },
    selectionChange(){
      var s = this.selected.sort((a,b) => (a > b) ? -1 : ((a < b) ? 1 : 0)).join(',');
      this.changed = (this.selected.length == this.nbCompulsoriesToSelect) && (s != this.selectionRead);
      //console.log('selectionChange', {s: s, selected: this.selected, nbCompulsoriesToSelect: this.nbCompulsoriesToSelect, selectionRead: this.selectionRead});
    },
    saveChanges(){
      var baseUrl = store.getters["config/serverBaseUrl"];
      var url = baseUrl + '/api/setSeasonCompulsories.php';
      var setOfData = {
        season: this.selectedSeason,
        competitionType: this.selectedCompetitionType,
        level: this.selectedLevel,
        category: this.selectedCategory,
        compulsories: this.selected
      };
      axios
        .post(url, setOfData )
        .then(response => {
          var data = response.data;
          if(!data.success)
            this.toastSaveFailure();
          else{
            this.synchronizeSeasonCompulsories();
            this.toastSaved();
          }
        })
        .catch(error => {
          console.log('ERROR =>', error);
          this.toastError();
        })
    }
  },
  mounted(){
    this.$hideLoader();
  }
}
</script>
